.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    align-items: flex-end;
}
.loader-bg {
    background-color: #000;
    height: 100%;
    width: calc(100% / 3);
    transition: height 0.6s ease-in-out;
}
.loader .loader-bg:nth-of-type(2) {
    transition-delay: 0.2s;
}
.loader .loader-bg:nth-of-type(3) {
    transition-delay: 0.4s;
}
.loader-txt {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    text-align: center;
    transition: opacity 0.6s ease;
}
.loader-txt-feature {
    font-size: 28px;
    display: inline-block;
    margin: 0 20px;
    opacity: 0;
    transform: translateY(200%);
    animation: txtUp 0.5s ease-in-out 0.2s forwards;
}
.loader-txt .loader-txt-feature:nth-of-type(2) {
    animation-delay: 0.5s;
}
.loader-txt .loader-txt-feature:nth-of-type(3) {
    animation-delay: 0.8s;
}

.loader.close .loader-bg {
    height: 0;
}
.loader.close .loader-txt {
    opacity: 0;
}

@keyframes txtUp {
    from {
        opacity: 0;
        transform: translateY(200%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 700px) {
    .loader-txt-feature {
        display: block;
        margin: 15px 0;
    }
}
