.homepage {
    position: relative;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    transition: transform 1s ease;
}

.homepage-title .highlight:nth-of-type(2):before {
    animation-delay: 0.5s;
}
.homepage-title .highlight:nth-of-type(3):before {
    animation-delay: 0.8s;
}
