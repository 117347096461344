.work {
    margin-top: 50px;
    transition: left 1s ease;
}
.work-item {
    margin-top: 30px;
}
.work-item.enter {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(150%);
}
.work-item.enter.enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1s ease;
}
.work-item.exit {
    opacity: 1;
    transform: translateX(0);
}
.work-item.exit.exit-active {
    opacity: 0;
    transform: translateX(-150%);
    transition: all 1s ease;
}
.work-item-heading {
    display: inline-block;
}
.work-item-text {
    width: 60%;
}
.work-item-tech {
    margin: 30px 0 0;
    font-size: 1.2rem;
}

.work-item-bottom {
    margin-top: 40px;
}
.work-item-bottom .content {
    position: relative;
    border: 5px solid #ececec;
    border-radius: 5px;
    overflow: hidden;
    margin: auto;
    transition: width 0.6s ease-in-out;
}
.content-bar {
    background-color: #ececec;
    padding: 5px 10px;
}
.content-bar-dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #989898;
}
.content-bar .content-bar-dot:not(:last-of-type) {
    margin-right: 5px;
}
.work-item-bottom .content .frame {
    border: none;
    display: block;
    width: 100%;
    height: 600px;
    background-color: #fff;
    transform-origin: left top;
}

/* Content Controls */
.content-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.content-control {
    margin: 0 10px;
    cursor: pointer;
}
.content-control.highlight:before {
    height: 8px;
    bottom: -2px;
}

@media only screen and (max-width: 900px) {
    .work-item-text {
        width: 100%;
        font-size: 1.3rem;
    }
    .work-item-text p {
        font-size: 1.3rem;
    }
    .work-item-bottom .desktop {
        height: calc(80vh - 60px);
    }
}
