@import url("https://fonts.googleapis.com/css?family=Work+Sans&display=swap");
html {
    margin: 0;
    padding: 0;
}
body.loading {
    overflow: hidden;
}
body.loading section {
    display: none;
}

/* Fonts */
body {
    font-family: "Work Sans", sans-serif;
    color: #000;
    font-size: 18px;
}
h1 {
    font-size: 60px;
    font-weight: 100;
    line-height: 1.1em;
    margin: 0;
}
h2 {
    font-size: 55px;
    font-weight: 100;
    line-height: 1.1em;
    margin: 0;
}
h3 {
    font-size: 35px;
    font-weight: 100;
    line-height: 1.1em;
    margin: 0;
}
p {
    font-size: 1.2rem;
}

/* Highlight */
.highlight {
    position: relative;
    display: inline-block;
}
.highlight:before {
    content: "";
    position: absolute;
    height: 40%;
    width: 0;
    bottom: 0px;
    background: #faed27;
    z-index: -1;
    transition: width 0.3s ease;
}
.in-view > .highlight:before {
    width: 100%;
}
.ready .highlight:before {
    animation: highlightAnimate 0.3s ease-in-out 0.2s forwards;
}
@keyframes highlightAnimate {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

/* Layout */
section {
    position: relative;
}
.container {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: auto;
}
.container.full {
    display: flex;
    align-items: center;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
}
.content-offctr {
    width: 70%;
}
.scroll {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 8%;
    z-index: 2;
}
.scroll .highlight:before {
    animation-delay: 1.1s;
}

/* Mobile styles */
@media only screen and (max-width: 1260px) {
    .container {
        max-width: unset;
        width: calc(100% - 60px);
        padding: 0 30px;
    }
}

@media only screen and (max-width: 900px) {
    .content-offctr {
        width: 100% !important;
    }
}

@media only screen and (max-width: 700px) {
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 35px;
    }
    h3 {
        font-size: 25px;
    }
    p {
        font-size: 1.1rem;
    }
}
