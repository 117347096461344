@import url(https://fonts.googleapis.com/css?family=Work+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
    margin: 0;
    padding: 0;
}
body.loading {
    overflow: hidden;
}
body.loading section {
    display: none;
}

/* Fonts */
body {
    font-family: "Work Sans", sans-serif;
    color: #000;
    font-size: 18px;
}
h1 {
    font-size: 60px;
    font-weight: 100;
    line-height: 1.1em;
    margin: 0;
}
h2 {
    font-size: 55px;
    font-weight: 100;
    line-height: 1.1em;
    margin: 0;
}
h3 {
    font-size: 35px;
    font-weight: 100;
    line-height: 1.1em;
    margin: 0;
}
p {
    font-size: 1.2rem;
}

/* Highlight */
.highlight {
    position: relative;
    display: inline-block;
}
.highlight:before {
    content: "";
    position: absolute;
    height: 40%;
    width: 0;
    bottom: 0px;
    background: #faed27;
    z-index: -1;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}
.in-view > .highlight:before {
    width: 100%;
}
.ready .highlight:before {
    -webkit-animation: highlightAnimate 0.3s ease-in-out 0.2s forwards;
            animation: highlightAnimate 0.3s ease-in-out 0.2s forwards;
}
@-webkit-keyframes highlightAnimate {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
@keyframes highlightAnimate {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

/* Layout */
section {
    position: relative;
}
.container {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: auto;
}
.container.full {
    display: flex;
    align-items: center;
    min-height: 100vh;
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
}
.content-offctr {
    width: 70%;
}
.scroll {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 8%;
    z-index: 2;
}
.scroll .highlight:before {
    -webkit-animation-delay: 1.1s;
            animation-delay: 1.1s;
}

/* Mobile styles */
@media only screen and (max-width: 1260px) {
    .container {
        max-width: unset;
        width: calc(100% - 60px);
        padding: 0 30px;
    }
}

@media only screen and (max-width: 900px) {
    .content-offctr {
        width: 100% !important;
    }
}

@media only screen and (max-width: 700px) {
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 35px;
    }
    h3 {
        font-size: 25px;
    }
    p {
        font-size: 1.1rem;
    }
}

.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    align-items: flex-end;
}
.loader-bg {
    background-color: #000;
    height: 100%;
    width: calc(100% / 3);
    -webkit-transition: height 0.6s ease-in-out;
    transition: height 0.6s ease-in-out;
}
.loader .loader-bg:nth-of-type(2) {
    -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
}
.loader .loader-bg:nth-of-type(3) {
    -webkit-transition-delay: 0.4s;
            transition-delay: 0.4s;
}
.loader-txt {
    position: absolute;
    width: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #fff;
    text-align: center;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease;
}
.loader-txt-feature {
    font-size: 28px;
    display: inline-block;
    margin: 0 20px;
    opacity: 0;
    -webkit-transform: translateY(200%);
            transform: translateY(200%);
    -webkit-animation: txtUp 0.5s ease-in-out 0.2s forwards;
            animation: txtUp 0.5s ease-in-out 0.2s forwards;
}
.loader-txt .loader-txt-feature:nth-of-type(2) {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
}
.loader-txt .loader-txt-feature:nth-of-type(3) {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
}

.loader.close .loader-bg {
    height: 0;
}
.loader.close .loader-txt {
    opacity: 0;
}

@-webkit-keyframes txtUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(200%);
                transform: translateY(200%);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes txtUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(200%);
                transform: translateY(200%);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@media only screen and (max-width: 700px) {
    .loader-txt-feature {
        display: block;
        margin: 15px 0;
    }
}

.navbar {
    position: fixed;
    width: 100%;
    left: 0;
    top: 8%;
    z-index: 10;
}
.navbar .container {
    display: flex;
    justify-content: space-between;
}
.navbar-menu {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.navbar-item {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: 1.1rem;
}
.navbar-item a {
    text-decoration: none;
    color: inherit;
}
.navbar .navbar-menu .navbar-item:not(:first-of-type) {
    margin-left: 20px;
}
.navbar-item:before {
    content: "";
    position: absolute;
    height: 9px;
    width: 0;
    bottom: 0px;
    background: #faed27;
    z-index: -1;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
}
.navbar-item:hover:before,
.navbar-item.active:before {
    width: 100%;
}

@media only screen and (max-width: 700px) {
    .navbar {
        background-color: #000;
        top: 0;
        color: #fff;
        padding: 20px 0;
    }
}

.homepage {
    position: relative;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    -webkit-transition: -webkit-transform 1s ease;
    transition: -webkit-transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease, -webkit-transform 1s ease;
}

.homepage-title .highlight:nth-of-type(2):before {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
}
.homepage-title .highlight:nth-of-type(3):before {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
}

#about h2.highlight:before {
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}
#about .content-offctr {
    width: 50%;
}
#about .in-view h2.highlight:before {
    width: 100%;
}
.about-picture {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 40%;
    height: auto;
}
.about-picture img {
    display: block;
    width: 100%;
    height: auto;
}
@media only screen and (max-width: 530px) {
    .about-picture {
        width: 58%;
    }
}

.work {
    margin-top: 50px;
    -webkit-transition: left 1s ease;
    transition: left 1s ease;
}
.work-item {
    margin-top: 30px;
}
.work-item.enter {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
}
.work-item.enter.enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.work-item.exit {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
.work-item.exit.exit-active {
    opacity: 0;
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%);
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.work-item-heading {
    display: inline-block;
}
.work-item-text {
    width: 60%;
}
.work-item-tech {
    margin: 30px 0 0;
    font-size: 1.2rem;
}

.work-item-bottom {
    margin-top: 40px;
}
.work-item-bottom .content {
    position: relative;
    border: 5px solid #ececec;
    border-radius: 5px;
    overflow: hidden;
    margin: auto;
    -webkit-transition: width 0.6s ease-in-out;
    transition: width 0.6s ease-in-out;
}
.content-bar {
    background-color: #ececec;
    padding: 5px 10px;
}
.content-bar-dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #989898;
}
.content-bar .content-bar-dot:not(:last-of-type) {
    margin-right: 5px;
}
.work-item-bottom .content .frame {
    border: none;
    display: block;
    width: 100%;
    height: 600px;
    background-color: #fff;
    -webkit-transform-origin: left top;
            transform-origin: left top;
}

/* Content Controls */
.content-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.content-control {
    margin: 0 10px;
    cursor: pointer;
}
.content-control.highlight:before {
    height: 8px;
    bottom: -2px;
}

@media only screen and (max-width: 900px) {
    .work-item-text {
        width: 100%;
        font-size: 1.3rem;
    }
    .work-item-text p {
        font-size: 1.3rem;
    }
    .work-item-bottom .desktop {
        height: calc(80vh - 60px);
    }
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
}
.pagination-inner {
    width: 50%;
}
.pagination-inner.next {
    text-align: right;
}
.pagination-item {
    display: inline-block;
    cursor: pointer;
}
.pagination-title {
    position: relative;
    font-size: 1.4rem;
}
.pagination-item .pagination-title:before {
    content: "";
    position: absolute;
    height: 12px;
    width: 0;
    bottom: -2px;
    background: #faed27;
    z-index: -1;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
}
.pagination-item:hover .pagination-title:before {
    width: 100%;
}

@media only screen and (max-width: 700px) {
    .pagination-inner {
        width: 100%;
        margin: 10px 0;
    }
}

