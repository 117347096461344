.navbar {
    position: fixed;
    width: 100%;
    left: 0;
    top: 8%;
    z-index: 10;
}
.navbar .container {
    display: flex;
    justify-content: space-between;
}
.navbar-menu {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.navbar-item {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: 1.1rem;
}
.navbar-item a {
    text-decoration: none;
    color: inherit;
}
.navbar .navbar-menu .navbar-item:not(:first-of-type) {
    margin-left: 20px;
}
.navbar-item:before {
    content: "";
    position: absolute;
    height: 9px;
    width: 0;
    bottom: 0px;
    background: #faed27;
    z-index: -1;
    transition: width 0.3s ease-in-out;
}
.navbar-item:hover:before,
.navbar-item.active:before {
    width: 100%;
}

@media only screen and (max-width: 700px) {
    .navbar {
        background-color: #000;
        top: 0;
        color: #fff;
        padding: 20px 0;
    }
}
